<!-- eslint-disable vue/valid-v-for -->
<template>
    <div class="container" ref="container">
        <div class="box">
            <div class="left">
                <div class="left-box">
                    <el-form :model="form" :rules="rules" ref="form" label-width="150px" class="demo-ruleForm">
                        <el-form-item label="检测权重" prop="validValue">
                            <el-input-number size="mini" :max="1" :min="0.1" v-model="form.validValue"
                                :step="0.05"></el-input-number>
                            <el-button style="margin-left:20px" type="warning" size="mini" @click="submit"
                                v-if="file">检测</el-button>
                        </el-form-item>

                        <el-form-item label="语言" prop="language">
                            <el-radio-group v-model="form.language" size="small" @input="change">
                                <el-radio-button label="中文"></el-radio-button>
                                <el-radio-button label="英文"></el-radio-button>
                            </el-radio-group>
                        </el-form-item>


                        <el-form-item label="识别类型" prop="weights">
                            <span>
                                <el-radio-group v-for="item in buttionArr" :key="item.id" v-model="form.weights"
                                @input="changeWeights" size="mini">
                                    <el-radio-button :label="item.code">{{item.menuName}}</el-radio-button>
                                </el-radio-group>
                            </span>
                          

                        </el-form-item>

                        <el-form-item label="图片切割" prop="language">
                            <el-radio-group v-model="form.imageToCut" size="small" @change="change">
                                <!-- 禁用el-radio，用disabled即可，如果是循环的选项，那么需要绑定disabled属性 -->
                                <el-radio-button v-for="(item, i) in isCutItems" :key="i" :label="item.id"
                                    :disabled="item.disabled">{{ item.content }}</el-radio-button>
                            </el-radio-group>
                        </el-form-item>


                        <el-form-item label="图片上传">
                            <el-upload class="upload-demo" ref="uploadFile" :auto-upload="false" drag action=""
                                :file-list="fileList" :show-file-list="false" list-type="picture" :limit="5"
                                :on-change="handelOnChange">
                                <i class="el-icon-upload"></i>
                                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                                <div class="el-upload__tip" slot="tip">请上传图片格式文件</div>
                            </el-upload>
                        </el-form-item>

                    </el-form>

                    <div class="">
                        <el-image class="image_view" :src="imgSrc" :preview-src-list="imgSrcList">
                        </el-image>
                    </div>
                </div>

            </div>

            <div class="right">
                <div>
                    识别结果：
                    <el-badge :value="normalization" style="margin:10px 30px 10px 0px" type="warning"
                        v-if="(result.length != 0)">
                        <el-button size="small" @click="targetChick('all')">全部</el-button>
                    </el-badge>
                    <el-badge v-for="(value, key) in result" :key="key" :value="value"   :hidden="value == 0"
                        style="margin:10px 30px 10px 0px" type="warning">
                        <el-button size="small" @click="targetChick(key)">{{ key }}</el-button>
                    </el-badge>

                </div>
                <div style="margin-top:20px">
                    <el-image class="image_right_view" :src="imgRightSrc" :preview-src-list="imgRightSrcList">
                    </el-image>
                </div>


            </div>
        </div>

    </div>
</template>

<script>
import axios from 'axios'

import { debounce } from "@/util/common.js";
import { Loading } from 'element-ui';
export default {
    mounted() {
   
    // 使用传递的参数 menuId 做一些操作
    },
    data() {
        return {
            form: {
                validValue: 0.5,
                weights: 'pest',
                language: "中文",
                imageToCut: 2
            },
            rules: {
                validValue: [
                    { required: true, message: '请输入校验值', trigger: 'blur' }
                ],
            },
            file: null,
            fileList: [],
            imgSrc: "",
            imgSrcList: [],
            imgRightSrc: "",
            imgRightSrcList: [],
            result: [],
            normalization: 0,
            target: 'all',
            tatgetCount: 0,
            isCutItems: [
                { id: 1, disabled: false, content: "是" },
                { id: 2, disabled: false, content: "否" },
            ],
            buttionArr: []


        };
    }, created() {
        this.getMenuButtions();
    },
    methods: {
        getMenuButtions() {
            //获取按钮权限
            const menuId = this.$route.query.meunId;
            this.$get("/user/getUserButton", {menuId:menuId}).then((res) => {
                if (res.success && res.statusCode == 200) {
                    this.buttionArr = res.data
                    console.log(this.buttionArr)
                    if (this.buttionArr.length > 0) {
                    this.form.weights = this.buttionArr[0].code;
                    }
                }
            });
        },
        clearFiles() {
            this.$refs.uploadFile.clearFiles();
            this.fileList = [];
            this.imgSrcList = [];
            this.imgRightSrcList = [];
            this.file = null;
        },
        handelOnChange(file) {
            this.clearFiles();
            this.fileList.push(file)
            this.file = file;
            this.imageToBase64(file.raw);

        },
        imageToBase64(file) {
            var reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
                reader.result
                this.imgSrc = reader.result;

                this.imgSrcList.push(reader.result);
                this.post(file, this.imgSrc);
            }
            reader.onerror = function (error) {
                console.log('Error: ', error)
            }
        },
        post(file, base64) {

            let options = { text: 'AI 正在识别中，请稍后...', body: false }

            let loadingInstance = Loading.service(options);
            let _this = this;

            let url = "http://mhp-company.mymhp.cn:9090/photo/upload"


            let param = new FormData() // 创建form对象
            param.append('fileBase64', base64) // 通过append向form对象添加数据
            param.append('fileName', file.name) // 添加form表单中其他数据
            param.append('passWord', '123qwe') // 添加form表单中其他数据
            param.append('target', this.target)
            param.append("validValue", this.form.validValue)
            param.append("isReturnImgData", true)
            let imageToCutTemp = this.form.imageToCut == "1" ? true : false
            param.append("imageToCut", imageToCutTemp)
            param.append("weights", this.form.weights)
            let language = "en";
            if (this.form.language === "中文") {
                language = "cn"
            }
            param.append("language", language)
            // withCredentials: true 使得后台可以接收表单数据 跨域请求
            const instance = axios.create({
                withCredentials: true
            })

            // url为后台接口
            instance.post(url, param)
                .then(function (res) {
                    if (res.data.code == '200') {
                        let resData = res.data.data[0];

                        _this.imgRightSrc = resData.imageBase64;
                        _this.imgRightSrcList.push(_this.imgRightSrc);
                        _this.result = resData.data;
                        _this.targetCount = 0;

                        //处理formatter的数据集合
                        if (resData.formatterData.length > 0) {
                            let obj = {}
                            resData.formatterData.forEach(item => {
                                obj[item] = (obj[item] + 1) || 1
                            })
                            _this.result = obj
                        }

                        const obj = resData.data;
                        console.log(obj)
                        let arr = Object.values(obj);
                        arr = arr.map(f => {
                            try {
                                return parseInt(f)
                            } catch (error) {
                                return f = 0;
                            }

                        });
                        console.log(arr)
                        const sum = arr.reduce((acc, cur) => {
                            return typeof cur === 'number' ? acc + cur : acc;
                        }, 0);
                        _this.normalization = sum
                        loadingInstance.close();
                    }

                })
                .catch(error => {
                    loadingInstance.close();
                })
        },

        submit: debounce(function () {
            this.target = 'all';
            this.imageToBase64(this.file.raw);
        }, 1000),
        targetChick: debounce(function (target) {
            this.target = target;
            this.imageToBase64(this.file.raw);
        }, 1000),
        change: debounce(function () {
            if (this.file) {
                this.imageToBase64(this.file.raw);
            }
        }, 1000),
        changeWeights: debounce(function (target) {
            if (target.includes("snail")) {
                this.isCutItems.forEach(f => {
                    if (f.id == 1) {
                        f.disabled = true;
                    }
                })
                this.form.imageToCut = 2;
            } else {
                this.isCutItems.forEach(f => {
                    f.disabled = false;
                })
            }

        }, 0)


    }
}
</script>

<style scoped>
.container {
    width: 100%;
    overflow: hidden;
    height: 90vh;
}

.box {
    padding-top: 20px;
    display: flex;

}

.left {
    width: 48%;
}

.left-box {
    width: 50%
}

.image_view {
    margin-left: 34%;
    width: 90%;
}

.right {
    width: 50%;
}

.image_right_view {
    width: 90%;

}
</style>